<template>
  <v-dialog v-model="visible" max-width="1200px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        [{{ patientData && patientData.nameFull }}] Action Care Plan
        {{ carePlanData.acp_id > 0 ? "Update" : "Add" }}
      </v-card-title>

      <ActionCarePlanForm
        ref="refActionCarePlanForm"
        :patientData="patientData"
        :carePlanData="carePlanData"
        @clickSave="clickSave"
      />

      <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
        <v-btn color="primary" depressed large @click="clickSave">{{
          carePlanData.acp_id > 0 ? "UPDATE" : "SAVE"
        }}</v-btn>
        <v-btn color="danger" outlined large @click="clickDelete">DELETE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Header from "./comp/Header.vue";
import ActionCarePlanForm from "./form/ActionCarePlanForm.vue";

const modelService = require("@/utils/dataModel");
const requireService = require("@/utils/requiredValue.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    Header,
    ActionCarePlanForm,
  },
  data() {
    return {
      visible: false,
      patientData: null,
      carePlanData: modelService.patientActionCarePlanModel(),
      cellRules: rulesService.cellRules(),
      emailRules: rulesService.emailRules(),
      reqData: modelService.reqDataModel(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
    };
  },
  methods: {
    Open(patientData, carePlanData) {
      this.visible = true;
      this.patientData = patientData;
      this.carePlanData =
        carePlanData === null
          ? modelService.patientActionCarePlanModel()
          : carePlanData;
      console.log("patientData", patientData);
      // this.reqData.hospital_id = clinicData.hospital_id;
      // this.clinicData = clinicData;
      // if (clinicData.hospital_id > 0) this.getHospitalData();

      // setTimeout(() => {
      //   this.$refs.refClinicAddForm.$refs.refHospitalName.focus();
      // }, 500);
    },
    close() {
      this.visible = false;
    },
    clickSave() {
      console.log("saveData", this.carePlanData);
      // return;
      if (!this.$refs.refActionCarePlanForm.$refs.form.validate()) return;
      // 필수~
      const $h = this.$helper;
      const D = this.carePlanData;
      let RF = this.$refs.refActionCarePlanForm.$refs;
      let msg = `Primary Care Physician ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.pcp_id, null, msg, false)) return;

      let tOj = RF.refCareTeam.$refs;
      msg = `Contact Person ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.contactPerson, tOj.refContactPerson, msg))return;

      tOj = RF.refCareOverView.$refs;
      msg = `Care Program ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.careProgram, null, msg, false)) return;

      msg = `Goal ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.goal, null, msg, false)) return;
      // return;

      const SaveAndUpdateLabel = D.acp_id > 0 ? "UPDATE" : "SAVE";
      if (!confirm(`${SaveAndUpdateLabel}?`)) return;
      this.saveData();
    },
    saveData() {
      const $h = this.$helper;
      const D = this.carePlanData;
      D.endpoint = "patient/medicationInfo/actionCarePlan";
      D.hospital_id = this.patientData.hospital_id;
      D.user_id = this.patientData.user_id;
      // 날짜처리
      D.dueDateStart = $h.changeDateToString(D.dueDateStart);
      D.dueDateEnd = $h.changeDateToString(D.dueDateEnd);
      console.log("saveData", D);
      this.saveDataQuery(D).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    clickDelete() {
      const DeleteLabel = "Delete";
      if (!confirm(`${DeleteLabel}?`)) return;
    },
    clickCancel() {
      this.visible = false;
    },
    // activeClinic(option) {
    //   this.clinicData.activated = option;
    //   this.saveAction(this.clinicData);
    // },
    // /** 데이터읽기  */
    // async getHospitalData() {
    //   this.reqData.endpoint = "hospitalOne";
    //   await this.getDataQuery(this.reqData).then((res) => {
    //     if (res === undefined) return;
    //     let resData = res.data;
    //     this.clinicData = resData;
    //     console.log("", resData);
    //   });
    // },
    // /** 저장/수정/삭제  */
    // async saveClinic() {
    //   if (!this.$refs.refClinicAddForm.$refs.form.validate()) return;

    //   console.log("", this.clinicData);
    //   const saveLbl = this.clinicData.hospital_id > 0 ? "Update" : "Register";
    //   if (!confirm(`Would you like to ${saveLbl}?`)) return;
    //   this.saveAction(this.clinicData);
    // },
    // async saveAction(data) {
    //   const $h = this.$helper;
    //   data.endpoint = "hospital";
    //   await this.saveDataQuery(data).then((res) => {
    //     if (res === undefined) return;
    //     $h.showTostMessage("", $h.commonMessage("S"), "success");
    //     this.visible = false;
    //     this.$emit("complete", res);
    //   });
    // },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.v-input {
  padding-top: 0 !important;
}
</style>