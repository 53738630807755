<template>
  <div class="footerUI">
    <div v-if="carePlanData">
      <div v-if="carePlanData.dpDateLabel">
        Update Date : {{ carePlanData.dpDateLabel }}
      </div>
      <div class="userPermitYN">
        <div class="text">Care plan review & share with patient</div>
        <RadioComps
          :items="userPermitItems"
          :prosSelected="userPermitValue"
          @changeType="userPermitChangeType"
        />
      </div>
    </div>
    <!-- <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
      <v-btn color="primary" depressed large @click="clickSave">SAVE</v-btn>
      <v-btn color="danger" outlined large @click="clickDelete">DELETE</v-btn>
    </v-card-actions> -->
  </div>
</template>
<script>
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
export default {
  components: { RadioComps },
  props: {
    carePlanData: {
      type: [Object, Array],
    },
  },
  watch: {
    carePlanData(val, oldVal) {
      // if (val !== oldVal) this.dataWatch();
      this.userPermitValue = val.userPermit;
    },
  },
  data() {
    return {
      // userPermit: this.carePlanData.userPermit,
      // yesNoOptions: ["Y", "N"],
      userPermitValue: "",
      userPermitItems: formatService.userPermitItems(),
    };
  },
  methods: {
    // clickSave() {
    //   this.$emit("clickSave");
    // },
    // clickDelete() {
    //   this.$emit("clickDelete");
    // },
    userPermitChangeType(e) {
      console.log("e", e);
      this.userPermitValue = e;
      this.carePlanData.userPermit = e;
    },
    // dataWatch() {
    //   console.log("dataWatch", this.carePlanData.userPermit);
    //   this.userPermit = this.carePlanData.userPermit;
    // },
    // onChangeYesNo(e) {
    //   this.carePlanData.userPermit = e.target.value;
    // },
  },
};
</script>
<style lang="scss">
.userPermitYN {
  display: flex;
  height: 30px;
  .text {
    padding: 7px 17px 7px 0;
  }
}
.footerUI {
  padding-top: 20px;
}
.uiButton {
  margin: 20px;
  width: 100%;
  text-align: center;
}
// .ui {
//   display: flex;
//   justify-content: flex-start;
//   .left {
//     margin-right: auto;
//   }
// }
</style>