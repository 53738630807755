<template>
  <v-dialog v-model="visible" max-width="600px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <SelectBoxPrimaryPhysician
              :label="$t('dialog.diagnosisCategory.provider')"
              searchValType="Provider"
              ref="refPrimaryPhysician"
              :userData="targetModel"
              :hospital_id="targetModel.hospital_id"
              :selectedValue="targetModel.provider_id"
              @updateSelect="updateSelectPrimaryPhysician"
            />
          </v-row>
          <v-row>
            <SelectBoxForTypeList
              :propsLabel="$t('dialog.diagnosisCategory.service')"
              :items="serviceList"
              :selectedValue="targetModel.service"
              @updateSelect="updateSelectService"
            />
          </v-row>
          <v-row>
            <SelectBoxForTypeList
              :propsLabel="$t('dialog.diagnosisCategory.diagnoses')"
              :items="diagnosisList"
              :selectedValue="targetModel.diagnosis"
              @updateSelect="updateSelectDiagnosis"
            />
          </v-row>
          <v-row>
            <SelectBoxICDCode
              :value="targetModel.icdCodeObj"
              @updateValue="selectedIcdCodeComplete"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <CommonBtn 
        :propsKey="targetModel.d_id" 
        :cancelBtnLabel="$t('common.button.cancel')"
        :saveBtnLabel="$t('common.button.update')"
        @save="clickSave" 
        @delete="deleteData" 
        @cancel="clickCancel"/>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../comp/Header.vue";
import CommonBtn from "./comp/CommonBtn.vue";
import SelectBoxPrimaryPhysician from "@/components/commonV2/ui/SelectBoxPrimaryPhysician.vue";
import SelectBoxForTypeList from "@/components/commonV2/ui/SelectBoxForTypeList.vue";
import SelectBoxICDCode from "@/components/commonV2/ui/SelectBoxICDCode.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBoxPrimaryPhysician,
    SelectBoxForTypeList,
    SelectBoxICDCode
  },
  data() {
    return {
      visible: false,
      editType: "",
      serviceList: formatService.serviceList(),
      diagnosisList: formatService.diagnosisList(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      targetModel: modelService.patientDiagnosisModel(),
      filedRules: rulesService.filedRules()
    };
  },
  methods: {
    showModal(editType, patientData, item) {
      this.visible = true;
      this.editType = editType;
      if (item === null) {
        this.targetModel = modelService.patientDiagnosisModel();
      } else {
        this.targetModel = item;
      }
      this.targetModel.user_id = patientData.user_id;
      this.targetModel.hospital_id = patientData.hospital_id;
      this.targetModel.endpoint = "patient/medicationInfo/diagnosis";
    },
    close() {
      this.visible = false;
    },
    updateSelectPrimaryPhysician(e) {
      this.targetModel.provider_id = e;
    },
    updateSelectService(e) {
      this.targetModel.service = e;
    },
    updateSelectDiagnosis(e) {
      this.targetModel.diagnosis = e;
    },
    selectedIcdCodeComplete(e) {
      this.targetModel.icdCode = e.data;
    },
    clickSave() {
      console.log('saveData', this.targetModel);
      const $h = this.$helper;
      const D = this.targetModel;
      let RF = this.$refs;
      let msg = `Provider ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.provider_id, null, msg, false)) return;

      msg = `Service Type ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.service, null, msg, false)) return;

      msg = `Diagnosis Type ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.diagnosis, null, msg, false)) return;
      
      if (!this.$refs.form.validate()) return;
      // return;
      this.saveData();
    },
    clickCancel() { 
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>