<template>
  <div class="box box1">
    <v-card class="editorPadding">
      <v-card-title>Care Team</v-card-title>
      <v-row class="ma-0 pa-0 mb-5">
        <v-col>
          <SelectBoxPrimaryPhysician
            label="Primary Physician"
            selectStyleWidth="width: 100%"
            ref="refPrimaryPhysician"
            :hospital_id="patientData.hospital_id"
            :userData="patientData"
            :selectedValue="carePlanData.pcp_id"
            :required="true"
            @updateSelect="updateSelectPrimaryPhysician"
          />
        </v-col>
      </v-row>
      <!-- <v-row class="ma-0 pa-0 mb-5">
        <v-col>
          <v-text-field
            label="Contact Person"
            ref="refContactPerson"
            v-model="carePlanData.contactPerson"
            clearable
            placeholder="* Contact Person"
            :rules="[filedRules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="CCM Service Person"
            ref="refCcmServicePerson"
            v-model="carePlanData.ccmServicePerson"
            clearable
            placeholder="CCM Service Person"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Social Work"
            ref="refSocialWork"
            v-model="carePlanData.socialWork"
            clearable
            placeholder="Social Work"
          />
        </v-col>
      </v-row> -->
    </v-card>
  </div>
</template>
<script>
import SelectBoxPrimaryPhysician from "@/components/commonV2/ui/SelectBoxPrimaryPhysician.vue";
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    SelectBoxPrimaryPhysician,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
    carePlanData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
    };
  },
  methods: {
    updateSelectPrimaryPhysician(e) {
      this.carePlanData.pcp_id = e;
    },
  },
};
</script>
<style lang="">
</style>