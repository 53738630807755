exports.inquiryData = () => {
    return {
        surveyDefine: "Patient Health", // 표시용 타이틀
        surveyTitle: "PatientHealth", // 저장용 타이틀(답변매칭용 값)
        set: [
            {
                "questionNO": 0,
                "question": "Little interest or pleasure in doing things",
                "element": [
                    "Not at all",
                    "Several days",
                    "More than half the days",
                    "Nearly every day",
                ]
            },
            {
                "questionNO": 1,
                "question": "Feeling down, depressed or hopeless",
                "element": [
                    "Not at all",
                    "Several days",
                    "More than half the days",
                    "Nearly every day",
                ]
            },
            {
                "questionNO": 2,
                "question": "Trouble falling asleep, staying asleep, or sleeping too much",
                "element": [
                    "Not at all",
                    "Several days",
                    "More than half the days",
                    "Nearly every day",
                ]
            },
            {
                "questionNO": 3,
                "question": "Feeling tired or having little energy",
                "element": [
                    "Not at all",
                    "Several days",
                    "More than half the days",
                    "Nearly every day",
                ]
            },
            {
                "questionNO": 4,
                "question": "Poor appetite or overeating",
                "element": [
                    "Not at all",
                    "Several days",
                    "More than half the days",
                    "Nearly every day",
                ]
            },
            {
                "questionNO": 5,
                "question": "Feeling bad about yourself - or that you’re a failure or have let yourself or your family down",
                "element": [
                    "Not at all",
                    "Several days",
                    "More than half the days",
                    "Nearly every day",
                ]
            },
            {
                "questionNO": 6,
                "question": "Trouble concentrating on things, such as reading the newspaper or watching television",
                "element": [
                    "Not at all",
                    "Several days",
                    "More than half the days",
                    "Nearly every day",
                ]
            },
            {
                "questionNO": 7,
                "question": "Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual",
                "element": [
                    "Not at all",
                    "1~2 times a week",
                    "3~4 times a week",
                    "5~6 times a week",
                    "Take it every day"
                ]
            },
            {
                "questionNO": 8,
                "question": "Thoughts that you would be better off dead or of hurting yourself in some way",
                "element": [
                    "Not at all",
                    "1~3 times a week",
                    "4~6 times a week",
                    "Once a day ",
                    "More than twice a day"
                ]
            },

        ]
    }
};

// exports.inquiryDataEn = () => {
//     return [
//         {
//             "questionNO": 0,
//             "question": "What is your usual amount of activity?",
//             "element": [
//                 "Light activity (Work almost sitting or do not take exercise)",
//                 "Moderate activity (Work standing, or regular light exercise such as walking, cycling)",
//                 "Hard activity (Work with plenty of activity or intensively exercise 4~5 times per week including running, swimming)"
//             ]
//         },
//         {
//             "questionNO": 1,
//             "question": "How many times do you eat grains (rice, bread, noodles, potatoes, sweet potatoes) per day? ",
//             "element": [
//                 "Hardly eat or five times or more",
//                 "Once a day",
//                 "Twice a day",
//                 "3~4 times a day"
//             ]
//         },
//         {
//             "questionNO": 2,
//             "question": "How often do you eat meat, fish, eggs, beans, tofu?",
//             "element": [
//                 "Hardly eat or six times or more",
//                 "Once a day",
//                 "Twice a day",
//                 "3rd a day",
//                 "4 ~ 5th times a day"
//             ]
//         },
//         {
//             "questionNO": 3,
//             "question": "How many times do you eat vegetables, seaweed, and mushrooms per day?",
//             "element": [
//                 "Hardly eat",
//                 "Twice a day",
//                 "3rd a day",
//                 "4 ~ 6th times a day",
//                 "7 times or more"
//             ]
//         },
//         {
//             "questionNO": 4,
//             "question": "How often do you eat fruit? ",
//             "element": [
//                 "Hardly eat",
//                 "1 ~ 3 times a week",
//                 "4 ~ 6 times a week",
//                 "Once a day",
//                 "Over twice a day"
//             ]
//         },
//         {
//             "questionNO": 5,
//             "question": "How often do you eat milk or dairy products? ",
//             "element": [
//                 "Do not eat",
//                 "1 ~ 3 times a week",
//                 "4 ~ 6 times a week",
//                 "Over Once a day",
//                 "Over twice a day"
//             ]
//         },
//         {
//             "questionNO": 6,
//             "question": "How many times do you eat per day?",
//             "element": [
//                 "Hardly eat",
//                 "One meal a day",
//                 "Two meals a day",
//                 "Three meals or more a day"
//             ]
//         },
//         {
//             "questionNO": 7,
//             "question": "Do you eat a proper breakfast?",
//             "element": [
//                 "Hardly eat",
//                 "1 ~ 2 times a week",
//                 "3 ~ 4 times a week",
//                 "5 ~ 6 times a week",
//                 "Eat every day"
//             ]
//         },
//         {
//             "questionNO": 8,
//             "question": "How often do you eat fast food, cookies, fatty bread, ramen, and fried foods (based on one item, one bag)?",
//             "element": [
//                 "Hardly eat",
//                 "1 ~ 3 times a week",
//                 "4 ~ 6 times a week",
//                 "Once a day",
//                 "Over twice a day"
//             ]
//         },
//         {
//             "questionNO": 9,
//             "question": "How often do you eat sweet foods (chocolate, candy, syrup, soda, cakes, sweetened beverages) (based on one bag, one item, one paper cup)?",
//             "element": [
//                 "Hardly eat",
//                 "1 ~ 2 times a week",
//                 "4 ~ 6 times a week",
//                 "Once a day",
//                 "Over twice a day"
//             ]
//         },
//         {
//             "questionNO": 10,
//             "question": "How often do you dine out or order spicy, salty, or delivery foods (all foods except your own cooking)?",
//             "element": [
//                 "5th or more a week",
//                 "4times a week",
//                 "3times a week",
//                 "2times a week",
//                 "Once or less a week"
//             ]
//         },
//         {
//             "questionNO": 11,
//             "question": "How often do you have a late-night snack?",
//             "element": [
//                 "5th or more a week",
//                 "4times a week",
//                 "3times a week",
//                 "2times a week",
//                 "Once or less a week"
//             ]
//         },
//         {
//             "questionNO": 12,
//             "question": "What body type do you think you have?",
//             "element": [
//                 "Body type with thin arms and legs (Thin torso and long, thin arms and legs)",
//                 "Muscular body type (Firm body, and easily built muscles)",
//                 "Chubby body type (short neck, big torso, and thick arms and legs)"
//             ]
//         },
//         {
//             "questionNO": 13,
//             "question": "How often do you exercise per week on average?",
//             "element": [
//                 "Within one day (including no exercise)",
//                 "Two days",
//                 "Three days",
//                 "Four days",
//                 "Five days or more"
//             ]
//         },
//         {
//             "questionNO": 14,
//             "question": "What is your average exercise intensity (daily activity intensity if you do not exercise)?",
//             "element": [
//                 "Very light",
//                 "Light",
//                 "Moderate",
//                 "Hard",
//                 "Very hard"
//             ]
//         },
//         {
//             "questionNO": 15,
//             "question": "How long do you exercise for?",
//             "element": [
//                 "Within 10 min (including no exercise",
//                 "20 min",
//                 "30 min",
//                 "40 min",
//                 "50 min or more"
//             ]
//         },
//         {
//             "questionNO": 16,
//             "question": "What is your common exercise form (daily activity type if you do not exercise)?",
//             "element": [
//                 "Aerobic exercise - 100%",
//                 "Aerobic exercise – 70%, Anaerobic exercise – 30%",
//                 "Equal (the same ratio of aerobic to anaerobic exercise)",
//                 "Anaerobic exercise – 70%, Aerobic exercise – 30%",
//                 "Anaerobic exercise – 100%"
//             ]
//         }
//     ]
// };

// exports.educationDataKr = () => {
//     return [
//         {
//             contentType: '식이처방',
//             contentName: "다이어트 처방",
//             contentDes: "다이어트 내용",
//         },
//         {
//             contentType: '운동처방',
//             contentName: "운동 처방",
//             contentDes: "운동 내용",
//         },
//     ]
// };