<template>
  <v-dialog v-model="visible" max-width="1000px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        {{ targetModel.questionSet }} {{ editType }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
            <v-expansion-panel
              v-for="(item, index) in targetModel.questionArray"
              :key="index"
            >
              <v-expansion-panel-header
                ><b>{{ item.question }}</b></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <!-- data.answer -->
                <p>{{ item.element[targetModel.answer[index] - 1] }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>

        <div class="vGap"></div>
      </v-card-text>

      <CommonBtn
        :propsKey="targetModel.ss_id"
        :isShowSaveBtn="false"
        @save="clickSave"
        @delete="deleteData"
        @cancel="clickCancel"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../comp/Header.vue";
import CommonBtn from "./comp/CommonBtn.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

const phqServiceTest = require("@/components/commonV2/inquiry/inquiryQuestionFile/PHQ9QuestionnaireTest.js");
const phqService = require("@/components/commonV2/inquiry/inquiryQuestionFile/PHQ9Questionnaire.js");
const healthService = require("@/components/commonV2/inquiry/inquiryQuestionFile/PatientHealthQuestionnaire.js");
const citService = require("@/components/commonV2/inquiry/inquiryQuestionFile/6CITQuestionnaire.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBox,
  },
  data() {
    return {
      visible: false,
      editType: "",
      panel: [0],
      disabled: false,
      requiredMsg: requireService.requiredFieldAlertMessage(),
      targetModel: modelService.patientKeyBiometricsModel(),
      filedRules: rulesService.filedRules(),
      surveyData: phqServiceTest.inquiryData(),
    };
  },
  methods: {
    showModal(editType, patientData, item) {
      this.visible = true;
      this.editType = editType;

      if (item.questionSet === "PatientHealth")
        this.surveyData = healthService.inquiryData();
      if (item.questionSet === "PHQ9QuestionnaireTest")
        this.surveyData = phqServiceTest.inquiryData();
      if (item.questionSet === "PHQ9Questionnaire")
        this.surveyData = phqService.inquiryData();
      if (item.questionSet === "6CITQuestionnaire")
        this.surveyData = citService.inquiryData();

      if (item !== null) {
        this.mainType = item.questionSet;
        this.targetModel = {
          endpoint: "patient/surveySet",
          user_id: item.user_id,
          ss_id: item.ss_id,
          hospital_id: item.hospital_id,
          questionArray: this.surveyData.set,
          questionSet: item.questionSet,
          answer: this.$helper.changeStringToArr(item.answers),
        };

        console.log("", this.targetModel, item.questionSet);
      }
    },
    close() {
      this.visible = false;
    },
    clickSave() {
      // console.log('saveData', this.targetModel);
      const $h = this.$helper;
      const D = this.targetModel;

      if (!this.$refs.form.validate()) return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>