<template lang="">
    <v-card-text>
      <v-form ref="form" class="ACPBox">
        <v-row class="_top">
          <v-col cols="12">
            <CareTeam
              ref="refCareTeam"
              v-if="carePlanData"
              :patientData="patientData"
              :carePlanData="carePlanData"
            />
          </v-col>
        </v-row>
        <v-row class="_top">
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <CareOverView
                  ref="refCareOverView"
                  v-if="carePlanData"
                  :patientData="patientData"
                  :carePlanData="carePlanData"
                />
                <Assessment
                  ref="refAssessment"
                  v-if="carePlanData"
                  :patientData="patientData"
                  :carePlanData="carePlanData"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <Refer
                  ref="refRefer"
                  v-if="carePlanData"
                  :patientData="patientData"
                  :carePlanData="carePlanData"
                />
                <ActionPlan
                  ref="refActionPlan"
                  v-if="carePlanData"
                  :patientData="patientData"
                  :carePlanData="carePlanData"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <CareProgramConsent
              ref="refCareProgramConsent"
              :patientData="patientData"
              :carePlanData="carePlanData"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <FooterUI
              ref="refFooterUI"
              :carePlanData="carePlanData"
              @clickSave="clickSave"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
</template>
<script>
import CareTeam from "../comp/actionCarePlan/V2/CareTeam.vue";
import CareOverView from "../comp/actionCarePlan/V2/CareOverView.vue";
import Refer from "../comp/actionCarePlan/V2/Refer.vue";
import ActionPlan from "../comp/actionCarePlan/V2/ActionPlan.vue";
import Assessment from "../comp/actionCarePlan/V2/Assessment.vue";
import CareProgramConsent from "../comp/actionCarePlan/V2/CareProgramConsent.vue";
import FooterUI from "../comp/actionCarePlan/V2/FooterUI.vue";
export default {
  components: {
    CareTeam,
    CareOverView,
    Refer,
    ActionPlan,
    Assessment,
    CareProgramConsent,
    FooterUI,
  },
  props: {
    patientData: {
      type: [Object],
    },
    carePlanData: {
      type: [Object],
    },
  },
  methods: {
    clickSave() {
      this.$emit("clickSave");
    },
  },
};
</script>
<style lang="">
</style>