<template>
  <div class="box boxOnly">
    <v-card class="editorPadding">
      <v-card-title>Care Program Consent</v-card-title>
      <v-row>
        <v-col>
          <RadioComps
            :items="patientTypeItems"
            :prosSelected="patientTypeValue"
            @changeType="patientTypeChangeType"
          />
        </v-col> 
      </v-row>
      <!-- {{ patientData.patientType }} -->
      <div v-if="patientData.patientType === 'consent'">
        <!-- <v-row>
            <a-col :span="formLeft"> * CCM Start Date</v-col>
            <v-col>
              <a-input ref="refContactPerson" v-model="data.contactPerson" placeholder="" />
            </v-col>
          </v-row> -->
        <v-row>
          <v-col cols="10">
            <!-- <v-btn @click="clickSelectFiles">SELECT FILES</v-btn> -->
            <S3_FileUpload_V4
              :visible="false"
              ref="refS3FileUploader"
              uploadType="file"
              uploadDir="file"
              fileHeader="consentFile"
              accept=".pdf, .xlsx, .hwp, .doc, .docx, .ppt, .pptx, .jpg, .jpeg, .png, .gif"
              :fileInfo="fileInfo"
              @fileSelected="fileSelected"
              @fileSelectedCheck="fileSelectedCheckS3FileUploader"
              @uploadComplete="uploadComplete"
              @changeDeleteOpt="changeDeleteOpt"
            />
          </v-col>
          <v-col cols="2">
            <DatePickerWithInputText
              label="Consent Date"
              :value="consentDefaultDate"
              @onChangeDate="onChangeDateDatePicker"
            />
          </v-col>
        </v-row>

        <DataTable
          ref="DataTable"
          :loading="loading"
          :loadingText="loadingText"
          :tableKey="'rowSeq'"
          :footerHide="true"
          :singleSelect="true"
          :showSelect="false"
          :columns="tableColumns"
          :inputData="consentList"
          @imageClick="imageClick"
          @RemoveClick="RemoveClick"
        />
      </div>
    </v-card>

    <DialogImageViewer ref="refDialogImageViewer" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
import S3_FileUpload_V4 from "@/components/commonV2/ui/S3_FileUpload_V4.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogImageViewer from "@/components/commonV2/Dialog/DialogImageViewer.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");

export default {
  components: {
    RadioComps,
    DatePickerWithInputText,
    S3_FileUpload_V4,
    DataTable,
    DialogImageViewer,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
    carePlanData: {
      type: [Object, Array],
    },
  },
  watch: {
    patientData(val) {
      if (val === null) return;
      this.patientTypeValue = val.patientType;
      this.getConsentData();
      if (this.$refs.refS3FileUploader === undefined) return;
      this.$refs.refS3FileUploader._resetForm();
    },
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading list...",
      consentList: [],
      fileInfo: null,
      fileDeleteOpt: false,
      consentDefaultDate: this.$helper.getToDateStringType(),
      patientTypeValue: this.patientData.patientType,
      consentFileModel: modelService.uploadFilesModel(),
      patientTypeItems: formatService.patientTypeItems(),
      tableColumns: formatService.consentFilesTableColumns(),
    };
  },
  // mounted() {
  //   this.getConsentData();
  // },
  methods: {
    clickSelectFiles() {
      // this.$refs.refS3FileUploader.fileUploadHandler();
    },
    clickRow(item) {},
    /** consent form **/
    onChangeDateDatePicker(e) {
      if (e === "" || e === null) return;
      this.consentFileModel.consentDate = this.$helper.changeDateToString(e);
    },
    getConsentData() {
      this.consentFileModel.endpoint = "uploadFiles";
      this.consentFileModel.user_id = this.patientData.user_id;
      this.consentFileModel.hospital_id = this.patientData.hospital_id;
      this.consentFileModel.fileGbn = "consent";      
      this.getDataQuery(this.consentFileModel).then((resP) => {
        if (resP === undefined) return;
        // console.log('res', res);
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: this.$helper.changeDateToStringForDp(item.regDate,true),
            consentDateLabel: this.$helper.changeDateToStringForDp(item.consentDate),
            userType: 'uploadFiles' // rowClick추가
          }));
        }
        this.consentList = res;
      });
    },
    fileSelected(e) {
      this.consentFileModel.fileOriginalName = e.name;
      this.$refs.refS3FileUploader.uploadFileToS3();
    },
    changeDeleteOpt(e) {
      this.fileDeleteRequestImg = e;
    },
    /** S3업로드 처리 후 */
    uploadComplete(e) {
      console.log("uploadComplete", e);
      if (e === null) return;
      this.consentFileModel.fileUrl = e.Location;
      this.consentFileModel.patientType = this.patientData.patientType;
      this.saveAction();
    },
    /*** 업로드 후 */
    RemoveClickConsent(item) {
      if (!confirm("Are you sure you want to Remove?")) return;
      this.consentFileModel.f_id = item.f_id;
      this.consentFileModel.useYN = "N";
      this.saveAction();
    },
    saveAction() {
      console.log('this.consentFileModel', this.consentFileModel);
      if (this.consentFileModel.patientType === '' || this.consentFileModel.patientType === null) {
        this.$helper.showTostMessage("", "SaPlease select the consent option", "Success");
        return;
      }
      this.consentFileModel.endpoint = "uploadFiles";
      this.consentFileModel.user_id = this.patientData.user_id;
      this.consentFileModel.hospital_id = this.patientData.hospital_id;
      this.consentFileModel.fileGbn = "consent";      
      this.saveDataQuery(this.consentFileModel).then((res) => {
        if (res === undefined) return;
        this.getConsentData();
        // 초기화
        this.fileInfo = null;
        this.$refs.refS3FileUploader._resetForm();
      });
    },
    RemoveClick(item) {
      console.log('RemoveClick');
      if (!confirm('Are you sure you want to Remove?')) return;
      this.consentFileModel.f_id = item.f_id;
      this.consentFileModel.useYN = 'N';
      this.saveAction();
    },
    imageClick(item) {
      /** 이미지 파일이면 보이게 처리 */
      if (!this.$helper.checkImageFile(item.fileUrl)) {
        window.open(item.fileUrl, "_blank")
        return;
      }
      this.$refs.refDialogImageViewer.Open(item.fileUrl);
    },
    patientTypeChangeType(e) {
      this.patientData.patientType = e;
    },
    fileSelectedCheckS3FileUploader(e) {},
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.boxOnly {
  width: 98% !important;
  .inBox {
    width: 100% !important;
  }
}
</style>