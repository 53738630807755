<template>
  <div class="box box2">
    <v-card class="editorPadding">
      <v-card-title>Patient Assessment</v-card-title>
      <v-row class="ma-0 pa-0 mb-0">
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <v-text-field
            label="Obesity"
            v-model="carePlanData.targetGoal"
            :disabled="true"
          />
        </v-col>
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <RadioComps
            :isHorizontal="true"
            :items="AssessmentItems"
            :prosSelected="AssessmentValue1"
            @changeType="onChangeAssessment($event, 'obesity')"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mb-0">
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <v-text-field
            label="Eating Habits"
            v-model="carePlanData.targetGoal"
            :disabled="true"
          />
        </v-col>
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <RadioComps
            :isHorizontal="true"
            :items="AssessmentItems"
            :prosSelected="AssessmentValue2"
            @changeType="onChangeAssessment($event, 'eat')"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mb-0">
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <v-text-field
            label="Exercise(ADL)"
            v-model="carePlanData.targetGoal"
            :disabled="true"
          />
        </v-col>
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <RadioComps
            :isHorizontal="true"
            :items="AssessmentItems"
            :prosSelected="AssessmentValue3"
            @changeType="onChangeAssessment($event, 'adl')"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mb-0">
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <v-text-field
            label="Mental Wellness(PHG9)"
            v-model="carePlanData.targetGoal"
            :disabled="true"
          />
        </v-col>
        <v-col cols="12" md="6" class="pa-0 ma-0">
          <RadioComps
            :isHorizontal="true"
            :items="AssessmentItems"
            :prosSelected="AssessmentValue4"
            @changeType="onChangeAssessment($event, 'phq9')"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import CheckComps from "@/components/commonV2/ui/CheckComps.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const formatService = require("@/utils/format.js");
export default {
  components: {
    CheckComps,
    RadioComps
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
    carePlanData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      // goalList: formatService.careProgrammeGoalList(),
      // outComeList: formatService.primaryOutComeList(),
      AssessmentValue1: "red",
      AssessmentValue2: "green",
      AssessmentValue3: "orange",
      AssessmentValue4: "black",
      AssessmentItems: [
        { value: "red", label: "", color: "red" },
        { value: "orange", label: "", color: "orange" },
        { value: "green", label: "", color: "green" },
        { value: "black", label: "", color: "black" },
      ],
    };
  },
  methods: {
    onChangeAssessment(e, type) {
      console.log("onChangeAssessment : ", e, type);
    },
  },
};
</script>
<style lang="scss">
</style>