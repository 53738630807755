<template>
  <div class="box box2">
    <v-card class="editorPadding">
      <v-card-title>Program Over View</v-card-title>
      <v-row class="ma-0 pa-0 mb-5">
        <v-col>
          <SelectBox
            label="Care Program"
            selectStyleWidth="width: 100%"
            ref="refCareProgram"
            :value="carePlanData && carePlanData.careProgram"
            :items="careProgrammeList"
            :required="true"
            @changeSelect="updateSelectCareProgramme"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mb-5">
        <v-col>
          <SelectBox
            label="Care Goal"
            selectStyleWidth="width: 100%"
            ref="refGoal"
            :value="carePlanData && carePlanData.goal"
            :items="goalList"
            :required="true"
            @changeSelect="updateSelectGoal"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mb-5">
        <v-col>
          <SelectBox
            label="primary Outcome"
            selectStyleWidth="width: 100%"
            :value="carePlanData && carePlanData.primaryOutcome"
            :items="outComeList"
            :required="true"
            @changeSelect="updateSelectOutCome"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Target Goal"
            ref="refTargetGoal"
            v-model="carePlanData.targetGoal"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Target Goal(Average)"
            ref="refTargetGoalAverage"
            v-model="carePlanData.targetGoalAverage"
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DateRangeCompWithButton @complete="changeDateRange" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import DateRangeCompWithButton from "@/components/commonV2/ui/DateRangeCompWithButton.vue";
const formatService = require("@/utils/format.js");
export default {
  components: {
    SelectBox,
    DateRangeCompWithButton,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
    carePlanData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      goalList: formatService.careProgrammeGoalList(),
      outComeList: formatService.primaryOutComeList(),
      careProgrammeList: formatService.careProgrammeList(),
    };
  },
  methods: {
    // dateWatch() {
    //   this.selectedBtn =
    //     this.patientData.acp_id === 0
    //       ? "month3"
    //       : this.$helper.calDueMonth(
    //           this.patientData.dueDateStart,
    //           this.patientData.dueDateEnd
    //         );
    // },
    updateSelectCareProgramme(e) {
      this.carePlanData.careProgram = e;
    },
    updateSelectGoal(e) {
      this.carePlanData.goal = e;
    },
    updateSelectOutCome(e) {
      this.carePlanData.primaryOutcome = e;
    },
    changeDateRange(startDate, endDate) {
      console.log("changeDateRange", startDate, endDate);
      this.carePlanData.dueDateStart = startDate;
      this.carePlanData.dueDateEnd = endDate;
    },
  },
};
</script>
<style lang="">
</style>