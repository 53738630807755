<template>
  <v-dialog v-model="visible" max-width="600px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <SelectBox
              ref="refIndicator"
              label="Indicator"
              :value="targetModel.indicator"
              :items="indicatorItems"
              @changeSelect="onChangeIndicator"
            />
          </v-row>
          <v-row>
            <SelectBoxUnitWithText 
              ref="refIndicatorUnit"
              :propsData="targetModel"
              @updateSelect="updateSelectIndicatorUnit" />
          </v-row>
          <v-row>
            <v-text-field
              label="Indicator C.C"
              type="text"
              ref="refIndicatorCc"
              v-model="targetModel.indicatorCc"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <CommonBtn 
        :propsKey="targetModel.b_id" 
        @save="clickSave" 
        @delete="deleteData" 
        @cancel="clickCancel"
        />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../comp/Header.vue";
import CommonBtn from "./comp/CommonBtn.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import SelectBoxUnitWithText from "@/components/commonV2/ui/SelectBoxUnitWithText.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBox,
    SelectBoxUnitWithText
  },
  data() {
    return {
      visible: false,
      editType: "",
      requiredMsg: requireService.requiredFieldAlertMessage(),
      indicatorItems: formatService.indicatorItems(),
      indicatorUnitItems: formatService.heightList(),
      targetModel: modelService.patientKeyBiometricsModel(),
      filedRules: rulesService.filedRules()
    };
  },
  methods: {
    showModal(editType, patientData, item) {
      this.visible = true;
      this.editType = editType;
      if (item === null) {
        this.targetModel = modelService.patientKeyBiometricsModel();
      } else {
        this.targetModel = item;
      }
      this.targetModel.user_id = patientData.user_id;
      this.targetModel.hospital_id = patientData.hospital_id;
      this.targetModel.endpoint = "patient/keyBiometrics";
    },
    close() {
      this.visible = false;
    },
    onChangeIndicator(e) {
      this.targetModel.indicator = e;
      this.$refs.refIndicatorUnit._updateUnit(e);
    },
    updateSelectIndicatorUnit(e) {
      this.targetModel.indicatorUnit = e;
    },
    clickSave() {
      // console.log('saveData', this.targetModel);
      const $h = this.$helper;
      const D = this.targetModel;
      let RF = this.$refs;
      let msg = `Indicator ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.indicator, null, msg, false)) return;
      
      if (!this.$refs.form.validate()) return;

      msg = `Indicator Unit ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.indicatorUnit, null, msg, false)) return;

      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>