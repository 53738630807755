<template>
  <v-dialog v-model="visible" max-width="600px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-text-field
              :label="$t('dialog.foodAllergy.drugOrFoodName')"
              type="text"
              ref="refDrugFoodName"
              v-model="targetModel.drugFoodName"
              :rules="[filedRules.required]"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('dialog.foodAllergy.reaction')"
              type="text"
              ref="refReaction"
              v-model="targetModel.reaction"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('dialog.foodAllergy.severity')"
              type="text"
              ref="refSeverity"
              v-model="targetModel.severity"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <CommonBtn 
        :propsKey="targetModel.a_id" 
        :cancelBtnLabel="$t('common.button.cancel')"
        :saveBtnLabel="$t('common.button.update')"
        @save="clickSave" 
        @delete="deleteData" 
        @cancel="clickCancel"
        />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../comp/Header.vue";
import CommonBtn from "./comp/CommonBtn.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBox,
  },
  data() {
    return {
      visible: false,
      editType: "",
      requiredMsg: requireService.requiredFieldAlertMessage(),
      emergencyContactRelationList: formatService.emergencyContactRelationList(),
      targetModel: modelService.patientAllergyModel(),
      filedRules: rulesService.filedRules()
    };
  },
  methods: {
    showModal(editType, patientData, item) {
      this.visible = true;
      this.editType = editType;
      if (item === null) {
        this.targetModel = modelService.patientAllergyModel();
      } else {
        this.targetModel = item;
      }
      this.targetModel.user_id = patientData.user_id;
      this.targetModel.hospital_id = patientData.hospital_id;
      this.targetModel.endpoint = "patient/medicationInfo/allergy";
      this.targetModel.mainType = editType === 'Medication Allergy' ? 'Medication':'Food';
    },
    close() {
      this.visible = false;
    },
    clickSave() {
      // console.log('saveData', this.targetModel);
      const $h = this.$helper;
      const D = this.targetModel;
      
      if (!this.$refs.form.validate()) return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>