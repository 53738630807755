exports.inquiryData = () => {
    return {
        surveyDefine: "PHQ9Test", // 표시용 타이틀
        surveyTitle: "PHQ9QuestionnaireTest", // 저장용 타이틀(답변매칭용 값)
        set: [
            {
                "questionNO": 0,
                "question": "What is your usual amount of activity?",
                "element": [
                    "Light activity (Work almost sitting or do not take exercise)",
                    "Moderate activity (Work standing, or regular light exercise such as walking, cycling)",
                    "Hard activity (Work with plenty of activity or intensively exercise 4~5 times per week including running, swimming)"
                ]
            },
            {
                "questionNO": 1,
                "question": "How many times do you eat grains (rice, bread, noodles, potatoes, sweet potatoes) per day? ",
                "element": [
                    "Hardly eat or five times or more",
                    "Once a day",
                    "Twice a day",
                    "3~4 times a day"
                ]
            },
        ],
    };
};