<template>
  <div class="box box2">
    <v-card class="editorPadding">
      <v-card-title>Action Plan</v-card-title>
      <v-row class="ma-0 pa-0 mb-5">
        <v-col>
          <CheckComps
            :isHorizontal="false"
            :items="ActionPlanItems"
            :value="ActionPlanValue"
            @changeType="onChangeActionPlan"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import CheckComps from "@/components/commonV2/ui/CheckComps.vue";
const formatService = require("@/utils/format.js");
export default {
  components: {
    CheckComps
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
    carePlanData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      // goalList: formatService.careProgrammeGoalList(),
      // outComeList: formatService.primaryOutComeList(),
      // careProgrammeList: formatService.careProgrammeList(),
      ActionPlanValue: ["education", "foodIntake"],
      ActionPlanItems: [
        { value: "education", label: "Education Curation", color: "red" },
        { value: "foodIntake", label: "Food Intake for eating habits", color: "" },
        { value: "diet", label: "Diet / Menu Management", color: "" },
        { value: "mental", label: "Metal Care - Relax - Contents", color: "" },
        { value: "mental2", label: "Mental Care(Cognitive)", color: "" },
        { value: "exercise", label: "Exercise", color: "" },
      ],
    };
  },
  methods: {
    onChangeActionPlan(e) {
      console.log("", e);
    },
  },
};
</script>
<style lang="">
</style>