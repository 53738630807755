<template>
  <v-dialog v-model="visible" max-width="800px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <SelectBoxPrimaryPhysician
              :label="$t('dialog.medication.provider')"
              searchValType="Provider"
              ref="refPrimaryPhysician"
              :userData="targetModel"
              :hospital_id="targetModel.hospital_id"
              :selectedValue="targetModel.provider_id"
              @updateSelect="updateSelectPrimaryPhysician"
            />
          </v-row>
          <v-row>
            <SelectBoxDrug @complete="drugInfoComplete" />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('dialog.medication.activeIngredients')"
              type="text"
              ref="refActiveIngredients"
              v-model="targetModel.activeIngredients"
              :disabled="true"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('dialog.medication.strength')"
              type="text"
              ref="refStrength"
              v-model="targetModel.strength"
              :disabled="true"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('dialog.medication.route')"
              type="text"
              ref="refRoute"
              v-model="targetModel.route"
              :disabled="true"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('dialog.medication.dosageForm')"
              type="text"
              ref="refDosageForm"
              v-model="targetModel.dosageForm"
              :disabled="true"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('dialog.medication.amount')"
              type="text"
              ref="refAmount"
              v-model="targetModel.amount"
              :rules="[filedRules.required]"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('dialog.medication.frequency')"
              type="text"
              ref="refFrequency"
              v-model="targetModel.frequency"
              :rules="[filedRules.required]"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <CommonBtn 
        :propsKey="targetModel.m_id" 
        @save="clickSave" 
        @delete="deleteData" 
        @cancel="clickCancel"/>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../comp/Header.vue";
import CommonBtn from "./comp/CommonBtn.vue";
import SelectBoxPrimaryPhysician from "@/components/commonV2/ui/SelectBoxPrimaryPhysician.vue";
import SelectBoxDrug from "@/components/commonV2/ui/SelectBoxDrug.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBoxPrimaryPhysician,
    SelectBoxDrug,
  },
  data() {
    return {
      visible: false,
      editType: "",
      serviceList: formatService.serviceList(),
      diagnosisList: formatService.diagnosisList(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      targetModel: modelService.patientMedicationModel(),
      filedRules: rulesService.filedRules()
    };
  },
  methods: {
    showModal(editType, patientData, item) {
      this.visible = true;
      this.editType = editType;
      if (item === null) {
        this.targetModel = modelService.patientMedicationModel();
      } else {
        this.targetModel = item;
      }
      this.targetModel.user_id = patientData.user_id;
      this.targetModel.hospital_id = patientData.hospital_id;
      this.targetModel.endpoint = "patient/medicationInfo/medication";
    },
    close() {
      this.visible = false;
    },
    updateSelectPrimaryPhysician(e) {
      this.targetModel.provider_id = e;
    },
    drugInfoComplete(e) {
      console.log('e', e);
      this.targetModel.drugName = e.drugName;
      this.targetModel.activeIngredients = e.activeIngredients;
      this.targetModel.strength = e.strength;
      this.targetModel.route = e.route;
      this.targetModel.dosageForm = e.dosageFrom;
      setTimeout(() => {
        this.$refs.refAmount.focus();
      }, 300);
    },
    clickSave() {
      console.log('saveData', this.targetModel);
      const $h = this.$helper;
      const D = this.targetModel;
      let RF = this.$refs;
      let msg = `Provider ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.provider_id, null, msg, false)) return;

      msg = `Drug Name ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.drugName, null, msg, false)) return;
      
      if (!this.$refs.form.validate()) return;
      // return;
      this.saveData();
    },
    clickCancel() { 
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>