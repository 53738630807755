exports.inquiryData = () => {
    return {
        surveyDefine: "6CIT", // 표시용 타이틀
        surveyTitle: "6CITQuestionnaire", // 저장용 타이틀(답변매칭용 값)
        set: [
            {
                "questionNO": 0,
                "question": "What year is it?",
                "element": [
                    "Correct (0 pts)",
                    "Incorrect (4 pts)",
                ]
            },
            {
                "questionNO": 1,
                "question": "What month is it?",
                "element": [
                    "Correct (0 pts)",
                    "Incorrect (3 pts)",
                ]
            },
            {
                "questionNO": 2,
                "question": "Give the patient an address phrase to remember with 5 components eg) John, Smith, 42, High St, Bedford.",
                "element": [
                    "1",
                    "2",
                    "3",
                    "4",
                    "6",
                ]
            },
            {
                "questionNO": 3,
                "question": "About what time is it (within one hour)?",
                "element": [
                    "Correct (0 pts)",
                    "Incorrect (3 pts)",
                ]
            },
            {
                "questionNO": 4,
                "question": "Count backwards from 20-1",
                "element": [
                    "Correct (0 pts)",
                    "1 error (2 pts)",
                ]
            },
            {
                "questionNO": 5,
                "question": "Say the months of the year in reverse",
                "element": [
                    "Correct (0 pts)",
                    "1 error (2 pts)",
                ]
            },
            {
                "questionNO": 6,
                "question": "Repeat address phrase (Item no.3)",
                "element": [
                    "Correct (0 pts)",
                    "1 error (2 pts)",
                ]
            },
        ]
    }
};

// exports.inquiryData_old = () => {
//     return [
//         {
//             "questionNO": 0,
//             "question": "Little interest or pleasure in doing things",
//             "element": [
//                 "Not at all",
//                 "Several days",
//                 "More than half the days",
//                 "Nearly every day",
//             ]
//         },
//         {
//             "questionNO": 1,
//             "question": "Feeling down, depressed or hopeless",
//             "element": [
//                 "Not at all",
//                 "Several days",
//                 "More than half the days",
//                 "Nearly every day",
//             ]
//         },
//         {
//             "questionNO": 2,
//             "question": "Trouble falling asleep, staying asleep, or sleeping too much",
//             "element": [
//                 "Not at all",
//                 "Several days",
//                 "More than half the days",
//                 "Nearly every day",
//             ]
//         },
//         {
//             "questionNO": 3,
//             "question": "Feeling tired or having little energy",
//             "element": [
//                 "Not at all",
//                 "Several days",
//                 "More than half the days",
//                 "Nearly every day",
//             ]
//         },
//         {
//             "questionNO": 4,
//             "question": "Poor appetite or overeating",
//             "element": [
//                 "Not at all",
//                 "Several days",
//                 "More than half the days",
//                 "Nearly every day",
//             ]
//         },
//         {
//             "questionNO": 5,
//             "question": "Feeling bad about yourself - or that you’re a failure or have let yourself or your family down",
//             "element": [
//                 "Not at all",
//                 "Several days",
//                 "More than half the days",
//                 "Nearly every day",
//             ]
//         },
//         {
//             "questionNO": 6,
//             "question": "Trouble concentrating on things, such as reading the newspaper or watching television",
//             "element": [
//                 "Not at all",
//                 "Several days",
//                 "More than half the days",
//                 "Nearly every day",
//             ]
//         },
//         {
//             "questionNO": 7,
//             "question": "Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual",
//             "element": [
//                 "거의 먹지 않는다",
//                 "주 1~2회",
//                 "주 3~4회",
//                 "주 5~6회",
//                 "매일 챙겨 먹는다"
//             ]
//         },
//         {
//             "questionNO": 8,
//             "question": "Thoughts that you would be better off dead or of hurting yourself in some way",
//             "element": [
//                 "거의 먹지 않는다",
//                 "주 1~3회",
//                 "주 4~6회",
//                 "하루 1회 ",
//                 "하루 2회 이상"
//             ]
//         },

//     ]
// };

// exports.educationDataKr = () => {
//     return [
//         {
//             contentType: '식이처방',
//             contentName: "다이어트 처방",
//             contentDes: "다이어트 내용",
//         },
//         {
//             contentType: '운동처방',
//             contentName: "운동 처방",
//             contentDes: "운동 내용",
//         },
//     ]
// };