<template>
  <v-dialog v-model="visible" max-width="600px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <SelectBoxPrimaryPhysician
              :label="$t('dialog.encounter.provider')"
              searchValType="Provider"
              ref="refPrimaryPhysician"
              :userData="targetModel"
              :hospital_id="targetModel.hospital_id"
              :selectedValue="targetModel.provider_id"
              @updateSelect="updateSelectPrimaryPhysician"
            />
          </v-row>
          <v-row>
            <SelectTypeWithEtc 
              :label="$t('dialog.encounter.visitType')"
              :items="VisitTypeItems" 
              :propsData="targetModel"
              @complete="changeVisitType"/>
          </v-row>
          <v-row>
            <DateCompWithTime 
              :propsPlaceholder="$t('dialog.encounter.serviceDate')"
              :propsData="targetModel.serviceDateObj" 
              @complete="onChangeDateAndTime" />
          </v-row>
        </v-form>
      </v-card-text>

      <CommonBtn 
        :propsKey="targetModel.e_id" 
        :cancelBtnLabel="$t('common.button.cancel')"
        :saveBtnLabel="$t('common.button.update')"
        @save="clickSave" 
        @delete="deleteData" 
        @cancel="clickCancel"
        />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../comp/Header.vue";
import CommonBtn from "./comp/CommonBtn.vue";
import SelectBoxPrimaryPhysician from "@/components/commonV2/ui/SelectBoxPrimaryPhysician.vue";
import SelectTypeWithEtc from "@/components/commonV2/ui/SelectTypeWithEtc.vue";
import DateCompWithTime from "@/components/commonV2/ui/DateCompWithTime.vue";
import Cookies from 'js-cookie';
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    SelectTypeWithEtc,
    CommonBtn,
    SelectBoxPrimaryPhysician,
    DateCompWithTime,
  },
  data() {
    return {
      visible: false,
      editType: "",
      requiredMsg: requireService.requiredFieldAlertMessage(),
      filedRules: rulesService.filedRules(),
      VisitTypeItems: formatService.visitTypeItems(),
      serviceDateObj: null,
      targetModel: modelService.patientEncounterModel(),
    };
  },
  methods: {
    showModal(editType, patientData, item) {
      // console.log('item', item);
      this.visible = true;
      this.editType = editType;
      if (item === null) {
        this.targetModel = modelService.patientEncounterModel();
      } else {
        this.targetModel = item;
      }
      this.targetModel.user_id = patientData.user_id;
      this.targetModel.hospital_id = patientData.hospital_id;
      this.targetModel.endpoint = "patient/encounter";
      // console.log('targetModel', this.targetModel);
    },
    close() {
      this.visible = false;
    },
    updateSelectPrimaryPhysician(e) {
      this.targetModel.provider_id = e;
    },
    changeVisitType(e) {
      console.log("", e);
      this.targetModel.visitType = e.type;
      this.targetModel.visitTypeEtc = e.type;
    },
    onChangeDateAndTime(e) {
      console.log("", e);
      this.targetModel.serviceDate = e.date;
      this.targetModel.serviceTime = e.time;
    },
    clickSave() {
      // console.log('saveData', this.targetModel);
      const $h = this.$helper;
      const D = this.targetModel;
      let RF = this.$refs;
      let msg = `Provider ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.provider_id, null, msg, false)) return;

      msg = `Visit Type ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.visitType, null, msg, false)) return;

      msg = `Service Date ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.serviceDate, null, msg, false)) return;

      msg = `Service Time ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.serviceTime, null, msg, false)) return;
      
      if (!this.$refs.form.validate()) return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      // 로그인한 관리자(관리자일때만)
      this.targetModel.careCoordinator_id = this.accessInfo.user_id;
      this.targetModel.userType = this.accessInfo.userType;
      this.targetModel.serviceDate = this.$helper.changeDateToString(this.targetModel.serviceDate);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
  computed: {
    accessInfo() {
      const CookieName = process.env.VUE_APP_CookieName;
      return JSON.parse(Cookies.get(CookieName));
    },
  },
};
</script>