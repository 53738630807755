<template>
  <v-dialog v-model="visible" max-width="900px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        <h2>Care Note</h2>
      </v-card-title>

      <div class="subTitle">
        Program recode the code you have done.<br />
        You have to fill out the care note before you leave the page.
      </div>
      <CareNoteForm ref="refCareNoteForm" :careNoteData="careNoteData" />

      <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
        <v-btn color="primary" depressed large @click="saveData">SAVE</v-btn>
        <v-btn depressed large @click="withoutSaveClose"
          >Without Save Close</v-btn
        >
        <v-btn depressed large @click="clickCancel">CANCEL</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./comp/Header.vue";
import CareNoteForm from "@/_kimCare/patient/tabs/careNote/comp/CareNoteForm.vue";
const modelService = require("@/utils/dataModel.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CareNoteForm,
  },
  data() {
    return {
      visible: false,
      careNoteData: null,
      requiredMsg: requireService.requiredFieldAlertMessage(),
    };
  },
  methods: {
    _showModal(patientData) {
      this.visible = true;
      this.setCareNoteTimerClose(false);
      this.careNoteData = modelService.patientCareNoteModel();
      this.careNoteData.user_id = patientData.user_id;

      // 시간불러오는 곳
      setTimeout(() => {
        this.$refs.refCareNoteForm._getTimeSet();
      }, 300);
    },
    clickSave() {
      this.$refs.refCareNoteForTimerForm._checkForm();
    },
    // 취소버튼 클릭시
    clickCancel() {
      this.visible = false;
      this.$emit("clickCancel");
    },
    // 창을닫을때
    close() {
      this.visible = false;
      this.$emit("clickCancel");
      this.setCareNoteTimerClose(true);
    },
    // 저장 없이 종료
    withoutSaveClose() {
      this.$emit("withoutSaveClose");
      this.setCareNoteTimerClose(true);
    },
    afterClose() {
      this.visible = false;
      this.$emit("clickCancel");
      this.setCareNoteTimerClose(true);
    },
    checkCompleteCareNoteForTimerForm() {
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.careNoteData);
      // return;
      // 필수 확인!
      const $h = this.$helper;
      const D = this.careNoteData;
      let tOj = this.$refs.refCareNoteForm.$refs;
      let msg = `Care Time Date/Times ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.careTimeSet.date, null, msg, false)) return;

      msg = `Program ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.program, null, msg, false)) return;

      msg = `Active Log ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.activeLog, null, msg, false)) return;

      msg = `Active Log Type ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.activeLogType, null, msg, false)) return;

      D.endpoint = "patient/careNote";
      D.diffSec = this.mPatient.careTime.tik;
      this.saveDataQuery(D).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", "Saved", "info");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery", "setCareNoteTimerClose"]),
  },
  computed: {
    ...mapState(["mPatient"]),
  },
};
</script>
<style lang="scss">
.subTitle {
  font-weight: 300;
  font-size: 15px;
  padding-bottom: 40px;
  color: blue;
}
</style>