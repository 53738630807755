<template>
  <v-dialog v-model="visible" max-width="600px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-text-field
              label="* Vaccine"
              type="text"
              ref="refItem"
              v-model="targetModel.item"
              :rules="[filedRules.required]"
            />
          </v-row>
          <div class="vGap"></div>
          <v-row>
            <SelectBoxHowOftenWithShot 
              :selectedValue="targetModel.howOftenSet" 
              @complete="onChangeHowOftenShot"/>
          </v-row>
          <v-row>
            <DatePickerWithInputText 
              label="Vaccinated Date"
              selectStyleWidth="width: 100%"
              :value="$helper.changeDateToString(targetModel.completionDate)" 
              @onChangeDate="onChangeVaccinatedDate" />
          </v-row>
          <v-row>
            <DatePickerWithInputText 
              label="Appointment Date"
              selectStyleWidth="width: 100%"
              :value="$helper.changeDateToString(targetModel.appointment)" 
              @onChangeDate="onChangeAppointment" />
          </v-row>
        </v-form>
      </v-card-text>

      <CommonBtn 
        :propsKey="targetModel.pc_id" 
        @save="clickSave" 
        @delete="deleteData" 
        @cancel="clickCancel"
        />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../comp/Header.vue";
import CommonBtn from "./comp/CommonBtn.vue";
import SelectBoxHowOftenWithShot from "@/components/commonV2/ui/SelectBoxHowOftenWithShot.vue";
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBoxHowOftenWithShot,
    DatePickerWithInputText
  },
  data() {
    return {
      visible: false,
      editType: "",
      requiredMsg: requireService.requiredFieldAlertMessage(),
      emergencyContactRelationList: formatService.emergencyContactRelationList(),
      targetModel: modelService.patientPreventiveCareModel(),
      filedRules: rulesService.filedRules()
    };
  },
  methods: {
    showModal(editType, patientData, item) {
      this.visible = true;
      this.editType = editType;
      if (item === null) {
        this.targetModel = modelService.patientPreventiveCareModel();
      } else {
        this.targetModel = item;
      }
      this.targetModel.user_id = patientData.user_id;
      this.targetModel.hospital_id = patientData.hospital_id;
      this.targetModel.endpoint = "patient/medicationInfo/preventiveCare";
      this.targetModel.mainType = 'vaccinated'; //:'annual';
    },
    close() {
      this.visible = false;
    },
    clickCancel() {
      this.visible = false;
    },
    onChangeHowOftenShot(e) {
      console.log('e', e);
      this.targetModel.howOften = e.howOften;
      this.targetModel.shot = e.shot;
    },
    onChangeVaccinatedDate(e) {
      console.log('e', e);
      this.targetModel.completionDate = e;
    },
    onChangeAppointment(e) {
      console.log('e', e);
      this.targetModel.appointment = e;
    },
    clickSave() {
      console.log('saveData', this.targetModel);
      const $h = this.$helper;
      const D = this.targetModel;
      
      if (!this.$refs.form.validate()) return;

      let msg = `How Often ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.howOften, null, msg, false)) return;

      msg = `Shot ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.shot, null, msg, false)) return;

      msg = `Vaccinated Date ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.completionDate, null, msg, false)) return;

      msg = `Appointment Date ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.appointment, null, msg, false)) return;
      this.saveData();
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      const $h = this.$helper;
      this.targetModel.completionDate = $h.changeDateToString(this.targetModel.completionDate);
      this.targetModel.appointment = $h.changeDateToString(this.targetModel.appointment);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        $h.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>